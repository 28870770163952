import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import SortableList from "../components/sortableList";
import SpotlightService from "../data/api/spotlightService";
import ImageResource from "../data/imageResource";
import ContentDAO from "../data/models/content/ContentDAO";
import KeywordActionDAO from "../data/models/content/KeywordActionDAO";
import errorNotify from "./responseNotifyHelper";
const moduleImages = [
  {
    value: "TestIconKey",
    image: ImageResource.get("TestIconKey")
  },
  {
    value: "cardImage",
    image: ImageResource.get("cardImage")
  },
  {
    value: "AntiBribery_BriberyBusinessCourtesies",
    image: ImageResource.get("AntiBribery_BriberyBusinessCourtesies")
  },
  {
    value: "AntiBribery_HighSalesPrice",
    image: ImageResource.get("AntiBribery_HighSalesPrice")
  },
  {
    value: "AntiBribery_IntroToAntiBribery",
    image: ImageResource.get("AntiBribery_IntroToAntiBribery")
  },
  {
    value: "AntiBribery_LocalCharities",
    image: ImageResource.get("AntiBribery_LocalCharities")
  },
  {
    value: "AntiBribery_SalesAgent",
    image: ImageResource.get("AntiBribery_SalesAgent")
  },
  {
    value: "AntiBribery_SubDealers",
    image: ImageResource.get("AntiBribery_SubDealers")
  },
  {
    value: "AntiBribery_ThirdPartyIntermediaries",
    image: ImageResource.get("AntiBribery_ThirdPartyIntermediaries")
  },
  {
    value: "AntiBribery_VisaInvitationLetters",
    image: ImageResource.get("AntiBribery_VisaInvitationLetters")
  },
  {
    value: "DataPrivacy_AccidentalBreach",
    image: ImageResource.get("DataPrivacy_AccidentalBreach")
  },
  {
    value: "DataPrivacy_BreachResponse",
    image: ImageResource.get("DataPrivacy_BreachResponse")
  },
  {
    value: "DataPrivacy_Header",
    image: ImageResource.get("DataPrivacy_Header")
  },
  {
    value: "DataPrivacy_PersonalInformation",
    image: ImageResource.get("DataPrivacy_PersonalInformation")
  },
  {
    value: "DataPrivacy_PrivacyByDesign",
    image: ImageResource.get("DataPrivacy_PrivacyByDesign")
  },
  {
    value: "DataPrivacy_ThirdParties",
    image: ImageResource.get("DataPrivacy_ThirdParties")
  },
  {
    value: "DataPrivacy_TradeShows",
    image: ImageResource.get("DataPrivacy_TradeShows")
  },
  {
    value: "DataPrivacy_Websites",
    image: ImageResource.get("DataPrivacy_Websites")
  },
  {
    value: "FairCompetition_CompetitiveInformation1",
    image: ImageResource.get("FairCompetition_CompetitiveInformation1")
  },
  {
    value: "FairCompetition_CompetitorSupplier",
    image: ImageResource.get("FairCompetition_CompetitorSupplier")
  },
  {
    value: "FairCompetition_Header",
    image: ImageResource.get("FairCompetition_Header")
  },
  {
    value: "FairCompetition_StatisticalInformationExchanges",
    image: ImageResource.get("FairCompetition_StatisticalInformationExchanges")
  },
  {
    value: "FairCompetition_TradeAssociations",
    image: ImageResource.get("FairCompetition_TradeAssociations")
  },
  {
    value: "FairCompetition_TradeShows",
    image: ImageResource.get("FairCompetition_TradeShows")
  },
  {
    value: "FairCompetition_TrainingWithCompetitors",
    image: ImageResource.get("FairCompetition_TrainingWithCompetitors")
  },
  {
    value: "TradeCompliance_AntiBoycottCompliance",
    image: ImageResource.get("TradeCompliance_AntiBoycottCompliance")
  },
  {
    value: "TradeCompliance_Customs",
    image: ImageResource.get("TradeCompliance_Customs")
  },
  {
    value: "TradeCompliance_Diversion",
    image: ImageResource.get("TradeCompliance_Diversion")
  },
  {
    value: "TradeCompliance_ExportControls",
    image: ImageResource.get("TradeCompliance_ExportControls")
  },
  {
    value: "TradeCompliance_HandCarry",
    image: ImageResource.get("TradeCompliance_HandCarry")
  },
  {
    value: "TradeCompliance_Licensing",
    image: ImageResource.get("TradeCompliance_Licensing")
  },
  {
    value: "TradeCompliance_RedFlags",
    image: ImageResource.get("TradeCompliance_RedFlags")
  },
  {
    value: "TradeCompliance_Tours",
    image: ImageResource.get("TradeCompliance_Tours")
  },
  {
    value: "ConflictofInterestPhoto",
    image: ImageResource.get("ConflictofInterestPhoto")
  },
  {
    value: "InsiderTradingPhoto",
    image: ImageResource.get("InsiderTradingPhoto")
  },
  {
    value: "ConflictofInterestPersonalGain",
    image: ImageResource.get("ConflictofInterestPersonalGain")
  },
  {
    value: "ConflictofInterestSupplier",
    image: ImageResource.get("ConflictofInterestSupplier")
  },
  {
    value: "ConflictofInterestAdvise",
    image: ImageResource.get("ConflictofInterestAdvise")
  },
  {
    value: "ConflictofInterestLunch",
    image: ImageResource.get("ConflictofInterestLunch")
  },
  {
    value: "ConflictofInterestGifts",
    image: ImageResource.get("ConflictofInterestGifts")
  },
  {
    value: "ConflictofInterestSports",
    image: ImageResource.get("ConflictofInterestSports")
  },
  {
    value: "ConflictofInterestOutsideOrganization",
    image: ImageResource.get("ConflictofInterestOutsideOrganization")
  },
  {
    value: "ConflictofInterestNepotism",
    image: ImageResource.get("ConflictofInterestNepotism")
  },
  {
    value: "ConflictofInterestExamples",
    image: ImageResource.get("ConflictofInterestExamples")
  },
  {
    value: "InsiderTradingTipping",
    image: ImageResource.get("InsiderTradingTipping")
  },
  {
    value: "InsiderTradingFriend",
    image: ImageResource.get("InsiderTradingFriend")
  },
  {
    value: "InsiderTradingSupplier",
    image: ImageResource.get("InsiderTradingSupplier")
  },
  {
    value: "InsiderTradingOverhear",
    image: ImageResource.get("InsiderTradingOverhear")
  },
  {
    value: "InsiderTradingOpen",
    image: ImageResource.get("InsiderTradingOpen")
  },
  {
    value: "InsiderTradingPolicy",
    image: ImageResource.get("InsiderTradingPolicy")
  },
  {
    value: "InsiderTradingMNPI",
    image: ImageResource.get("InsiderTradingMNPI")
  }
];

const pageDescriptionList = [
  { value: "Learn" },
  { value: "LearnDetailPage" },
  { value: "CommonQuestions" },
  { value: "FilterQuestionsPage" },
  { value: "TPIScreen" },
  { value: "PreApproval" },
  { value: "CTOT" },
  { value: "AskAQuestion" },
  { value: "SubmittedScreen" },
  { value: "MyContacts" }
];

const PageDescriptionArray = [
  "Learn",
  "LearnDetailPage",
  "CommonQuestions",
  "FilterQuestionsPage",
  "TPIScreen",
  "PreApproval",
  "CTOT",
  "AskAQuestion",
  "SubmittedScreen",
  "MyContacts"
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "theme.palette.text.secondary"
  },
  avatar: {
    backgroundColor: "#ffcc00"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  header: {
    padding: 5
  },
  subheader: {
    padding: 5
  },
  heading: {
    fontSize: theme.typography.pxToRem(14)
  },
  subheading: {
    fontSize: theme.typography.pxToRem(12)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    alignItems: "center"
  },
  column: {
    flexBasis: "33.33%"
  },
  keywordsColumn: {
    flexBasis: "66.66%",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  keywordActionsText: {
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  container: {
    width: "100%"
  },
  customInput: {
    fontSize: theme.typography.pxToRem(15)
  },
  langaugeCode: {
    width: "25%"
  }
}));

const SpotlightModule = ({ submodules, refetchModules, submitData, setSpotlightContent }) => {
  const classes = useStyles();
  const [spotlightModules, setSpotlightModules] = useState([]);
  const [activeSpotlightModule, setActiveSpotlightModule] = useState(new ContentDAO());
  const [activeSpotlightModuleIndex, setActiveSpotlightModuleIndex] = useState(null);
  const [keywordModalVisible, setKeywordModalVisible] = useState(false);
  const [currentKeywordContent, setCurrentKeywordContent] = useState({
    index: -1,
    data: new KeywordActionDAO()
  });
  const [linkType, setLinkType] = useState(null);
  const moduleDescriptionList = useStoreState(state => state.modules.moduleDescriptionList);
  const moduleDescriptionArray = useStoreState(state => state.modules.moduleDescriptionArray);
  const [languageCode, setCurrentLanguageCode] = useState(
    useStoreState(state => state.language.language)
  );
  const fetchModulesThunk = useStoreActions(actions => actions.modules.fetchData);
  const fetchModules = useCallback(
    async lang => {
      await fetchModulesThunk(lang || "en");
    },
    [fetchModulesThunk]
  );

  const resetActiveModule = useCallback(() => {
    const initObject = {
      riskArea: "",
      languageCode: "en",
      contentType: "Modules",
      contentData: {
        category: "",
        question: "",
        answer: ""
      },
      keywordActions: []
    };
    const initLearningModule = new ContentDAO().toObject(initObject);
    setActiveSpotlightModule(initLearningModule);
    setActiveSpotlightModuleIndex(null);
    setLinkType(null);
  }, []);

  const updateActiveLearningModule = useCallback(() => {
    const updatedLearningModule = new ContentDAO().toObject(activeSpotlightModule);
    setActiveSpotlightModule(updatedLearningModule);
  }, [activeSpotlightModule]);

  const handleSubmitModule = useCallback(async () => {
    await submitData(activeSpotlightModule);
    await refetchModules();
  }, [activeSpotlightModule, refetchModules, submitData]);

  useEffect(() => {
    fetchModules(languageCode);
    setSpotlightModules(submodules);
    resetActiveModule();
  }, [resetActiveModule, submodules, languageCode, fetchModules]);

  const handleExpansionSelect = ({ data, index }) => {
    if (index === activeSpotlightModuleIndex) {
      setActiveSpotlightModuleIndex(null);
      return;
    }
    const initActiveLearningModule = new ContentDAO().toObject(data);
    setActiveSpotlightModuleIndex(index);
    setActiveSpotlightModule(initActiveLearningModule);
  };

  const handleCancelModule = () => {
    const learningModulesClone = [...spotlightModules];
    if (!activeSpotlightModule.getIdentifier()) {
      learningModulesClone.splice(activeSpotlightModuleIndex, 1);
      setSpotlightModules(learningModulesClone);
      resetActiveModule();
      return;
    }
    resetActiveModule();
  };

  const handleRemoveKeyword = index => {
    const keywordActionsClone = [...activeSpotlightModule.getKeywordActions()];
    keywordActionsClone.splice(index, 1);
    activeSpotlightModule.setKeywordActions(keywordActionsClone);
    updateActiveLearningModule();
  };

  const updateCurrentKeywordContent = () => {
    const { index, data } = currentKeywordContent;
    const updatedKeywordContent = new KeywordActionDAO(data);
    setCurrentKeywordContent({ index, data: updatedKeywordContent });
  };

  const handleKeywordContentSubmit = () => {
    const { index, data } = currentKeywordContent;
    const _keywordActions = [...activeSpotlightModule.getKeywordActions()];
    const keywordContentObject = new KeywordActionDAO().toObject(data);
    if (index === -1 && data.getAction() && data.getDescription() && data.getKeyword()) {
      _keywordActions.push(keywordContentObject);
      activeSpotlightModule.setKeywordActions(_keywordActions);
    } else {
      _keywordActions[index] = keywordContentObject;
      activeSpotlightModule.setKeywordActions(_keywordActions);
    }
    updateActiveLearningModule();
    setKeywordModalVisible(false);
    setCurrentKeywordContent({
      index: -1,
      data: new KeywordActionDAO()
    });
    setLinkType(null);
  };

  const SortableExpansionsContainer = SortableContainer(({ children }) => {
    return (
      <Box component="span" m={4}>
        {children}
      </Box>
    );
  });

  const SortableSubmoduleElement = SortableElement(content => {
    const { value, someIndex } = content;
    const index = someIndex;
    const learningModule = value;
    return (
      <ExpansionPanel
        expanded={activeSpotlightModuleIndex === index}
        key={learningModule.identifier}
        onChange={() => handleExpansionSelect({ data: learningModule, index })}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {learningModule.getContentData().getTitle()}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <Divider variant="middle" />
        <ExpansionPanelDetails className={classes.details}>
          <Grid container spacing={2}>
            <Grid container>
              <Grid container>
                <Grid item xs={2}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleIconKey` + learningModule.identifier}
                      id={`activeLearningModuleIconKeyID` + learningModule.identifier}
                      label="IconKey"
                      className={classes.textField}
                      defaultValue={""}
                      value={activeSpotlightModule.getContentData().getIconKey()}
                      select
                      onChange={({ target }) => {
                        activeSpotlightModule.getContentData().setIconKey(target.value);
                      }}
                      onBlur={updateActiveLearningModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput }}
                      fullWidth
                    >
                      {moduleImages.map(icon => {
                        const { value, image } = icon;
                        return (
                          <MenuItem key={value} value={value}>
                            <img src={image} alt="not found" width={100} height={100}></img>
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleRiskArea` + learningModule.identifier}
                      id={`activeLearningModuleRiskAreaID` + learningModule.identifier}
                      label="Risk Area"
                      className={classes.textField}
                      defaultValue={activeSpotlightModule.getRiskArea()}
                      onChange={({ target }) => {
                        activeSpotlightModule.setRiskArea(target.value);
                      }}
                      onBlur={updateActiveLearningModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput, readOnly: true }}
                      fullWidth
                    ></TextField>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <TextField
                      key={`activeLearningModuleTitle` + learningModule.identifier}
                      id={`activeLearningModuleTitleID` + learningModule.identifier}
                      label="Title"
                      className={classes.textField}
                      defaultValue={activeSpotlightModule.getContentData().getTitle()}
                      onChange={({ target }) => {
                        activeSpotlightModule.getContentData().setTitle(target.value);
                      }}
                      onBlur={updateActiveLearningModule}
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ className: classes.customInput }}
                      fullWidth
                    ></TextField>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div className={clsx(classes.column, classes.keywordActionsText)}>
              <Typography variant="subtitle2">Keyword Actions</Typography>
            </div>
            <div className={classes.keywordsColumn}>
              {activeSpotlightModule.getKeywordActions().map((keywordAction, index) => {
                return (
                  <Chip
                    key={keywordAction.keyword}
                    label={
                      keywordAction.keyword.length >= 33
                        ? keywordAction.keyword.slice(0, 30) + "..."
                        : keywordAction.keyword.slice(0, 30)
                    }
                    onClick={() => {
                      setCurrentKeywordContent({ index, data: keywordAction });
                      const action = keywordAction.getAction();
                      const description = keywordAction.getDescription();
                      const pageDescriptionSet = new Set(PageDescriptionArray);
                      const moduleDescriptionSet = new Set(moduleDescriptionArray);
                      if (action === "Link") {
                        if (pageDescriptionSet.has(description)) setLinkType("page");
                        if (moduleDescriptionSet.has(description)) setLinkType("module");
                      }
                      setKeywordModalVisible(true);
                    }}
                    onDelete={() => handleRemoveKeyword(index)}
                  />
                );
              })}
              <Chip
                label="Add Keyword"
                color="primary"
                onClick={() => {
                  setCurrentKeywordContent({
                    index: -1,
                    data: new KeywordActionDAO()
                  });
                  setLinkType(null);
                  setKeywordModalVisible(true);
                }}
              ></Chip>
            </div>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div className={clsx(classes.column, classes.keywordActionsText)}>
              <Typography variant="subtitle2">Sections</Typography>
            </div>
            <div className={classes.keywordsColumn}>
              <SortableList
                getListItems={() => activeSpotlightModule.getContentData().getSections()}
                setListItems={sectionList => {
                  activeSpotlightModule.getContentData().setSections(sectionList);
                  const activeLearningModuleClone = new ContentDAO().toObject(
                    activeSpotlightModule
                  );
                  setActiveSpotlightModule(activeLearningModuleClone);
                }}
                title="Section List"
              ></SortableList>
            </div>
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button size="small" onClick={handleCancelModule}>
            Cancel
          </Button>
          <Button size="small" color="primary" onClick={handleSubmitModule}>
            Save
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  });

  return (
    <div className={classes.root}>
      <SortableExpansionsContainer distance={1} axis="y">
        {spotlightModules.map(({ value }, index) => {
          return (
            <SortableSubmoduleElement
              key={`item-${value.identifier}-container`}
              collection="submodules"
              index={index}
              value={value}
              someIndex={index}
            ></SortableSubmoduleElement>
          );
        })}
      </SortableExpansionsContainer>
      <Dialog
        open={keywordModalVisible}
        onClose={() => {
          setLinkType(null);
          setKeywordModalVisible(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Edit Keyword Content</DialogTitle>
        <DialogContent>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            <TextField
              key="activeKeywordContent"
              id="keywordActionContent-LearningModules"
              label="Keyword"
              className={classes.textField}
              value={currentKeywordContent.data.getKeyword()}
              onChange={event => {
                const { value } = event.target;
                currentKeywordContent.data.setKeyword(value);
                updateCurrentKeywordContent();
              }}
              margin="normal"
            ></TextField>
            <TextField
              key="activeKeywordAction"
              id="keywordActionAction-LearningModules"
              label="Action"
              select
              className={classes.textField}
              value={currentKeywordContent.data.getAction()}
              onChange={event => {
                const { value } = event.target;
                currentKeywordContent.data.setAction(value);
                updateCurrentKeywordContent();
              }}
              margin="normal"
            >
              {[{ value: "Modal" }, { value: "Link" }].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            {currentKeywordContent.data.getAction() === "Link" ? (
              <Fragment>
                <FormControl component="fieldset" className={classes.formControl}>
                  <RadioGroup
                    aria-label="linkType"
                    name="linkType1"
                    value={linkType}
                    onChange={event => {
                      setLinkType(event.target.value);
                      currentKeywordContent.data.setDescription(null);
                      updateCurrentKeywordContent();
                    }}
                  >
                    <FormControlLabel
                      label="Page"
                      value="page"
                      control={<Radio checked={linkType === "page"} style={{ color: "#FFCC00" }} />}
                    />
                    <FormControlLabel
                      label="Module"
                      value="module"
                      control={
                        <Radio checked={linkType === "module"} style={{ color: "#FFCC00" }} />
                      }
                    />
                  </RadioGroup>
                </FormControl>
                {linkType === "page" ? (
                  <TextField
                    label="Description"
                    key="activeKeywordPageDescription"
                    id="keywordActionPageDescription-submoduleContent"
                    select
                    className={classes.textField}
                    value={currentKeywordContent.data.getDescription()}
                    onChange={event => {
                      const { value } = event.target;
                      currentKeywordContent.data.setDescription(value);
                      currentKeywordContent.data.setOptions("page");
                      updateCurrentKeywordContent();
                    }}
                    margin="normal"
                  >
                    {pageDescriptionList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label="Description"
                    key="activeKeywordModuleDescription"
                    id="keywordActionModuleDescription-submoduleContent"
                    select
                    className={classes.textField}
                    value={currentKeywordContent.data.getDescription()}
                    onChange={event => {
                      const { value } = event.target;
                      currentKeywordContent.data.setDescription(value);
                      currentKeywordContent.data.setOptions("module");
                      updateCurrentKeywordContent();
                    }}
                    margin="normal"
                  >
                    {moduleDescriptionList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Fragment>
            ) : (
              <TextField
                label="Description"
                key="activeKeywordDescription"
                id="keywordActionDescription-submoduleContent"
                multiline
                className={classes.textField}
                value={currentKeywordContent.data.getDescription()}
                onChange={event => {
                  const { value } = event.target;
                  currentKeywordContent.data.setDescription(value);
                  updateCurrentKeywordContent();
                }}
                margin="normal"
              ></TextField>
            )}
          </FormGroup>
          <DialogActions>
            <Button onClick={() => setKeywordModalVisible(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleKeywordContentSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default function HomePage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [inputEdited, setInputEdited] = useState(false);
  const updateLanguage = useStoreActions(actions => actions.language.changeLanguage);
  const [languageCode, setCurrentLanguageCode] = useState(
    useStoreState(state => state.language.language)
  );
  // eslint-disable-next-line
  const [currentUserAccess, setCurrentUserAccess] = React.useState(
    useStoreState(state => state.accessLevel.accessLevel)
  );
  const [isError, setIsErrorr] = React.useState(
    useStoreState(state => state.isError.isError));
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [ceoMessageContent, setCeoMessageContent] = useState(
    new ContentDAO().toObject({
      contentType: "CEOMessage",
      languageCode: languageCode,
      contentData: {
        ceoMessage: ""
      }
    })
  );
  const [spotlightContent, setSpotlightContent] = useState(
    new ContentDAO().toObject({
      contentType: "Spotlight",
      riskAreaIconKey: "SomeKey",
      riskArea: "Spotlight",
      languageCode: languageCode,
      contentData: {
        iconKey: "",
        title: "",
        level: "Learning Module",
        levelOrder: ""
      }
    })
  );

  const fetchSpotlightItem = useCallback(async (lang) => {
    try {
      const response = await SpotlightService.getSpotlightItem(lang);
      const [ceoMessageContent, spotlightContent] = response;

      if (ceoMessageContent) {
        setCeoMessageContent(ceoMessageContent);
      } else {
        const temp = new ContentDAO().toObject({
          contentType: "CEOMessage",
          languageCode: languageCode,
          contentData: {
            ceoMessage: ""
          }
        });
        setCeoMessageContent(temp);
      }

      if (spotlightContent) {
        setSpotlightContent(spotlightContent);
      } else {
        const temp = new ContentDAO().toObject({
          contentType: "Spotlight",
          riskAreaIconKey: "SomeKey",
          languageCode: languageCode,
          contentData: {
            iconKey: "",
            title: "",
            level: "Learning Module",
            levelOrder: ""
          }
        })
        setSpotlightContent(temp);
      }
    } catch (error) {
      errorNotify(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitCEOMessage = useCallback(async () => {
    setLoading(true);
    try {
      await SpotlightService.updateSpotlightItem({
        spotlightType: "CEOMessage",
        ceoMessage: ceoMessageContent.getContentData().getCEOMessage(),
        languageCode: languageCode
      });
      setLoading(false);
    }
    catch (error) {
      errorNotify(error);
    }
  }, [ceoMessageContent, languageCode]);

  const submitSpotlightItem = useCallback(async data => {
    setLoading(true);
    try {
      await SpotlightService.updateSpotlightItem({
        spotlightType: "Spotlight",
        languageCode: languageCode,
        keywordActions: data.getKeywordActions(),
        module: data.getContentData()
      });
      setLoading(false);
    }
    catch (error) {
      errorNotify(error);
    }
  }, [languageCode]);

  const updateContent = () => {
    const updatedCeoMessageContent = new ContentDAO().toObject(ceoMessageContent);
    const updatedSpotlightContent = new ContentDAO().toObject(spotlightContent);
    setCeoMessageContent(updatedCeoMessageContent);
    setSpotlightContent(updatedSpotlightContent);
  };

  const localToken = localStorage.getItem("accessToken");
  const fetchItems = useCallback(async (lang) => {
    const language = lang || 'en';
    if (!isError) { setLoading(true); }
    setLoading(true);
    if (localToken !== null) {
      if (localToken.length > 9) {
        await Promise.all([fetchSpotlightItem(language)]);
        setLoading(false);
      }
    }
  }, [fetchSpotlightItem, isError]);

  useEffect(() => {
    if(isError)
    {
      setShowErrorModal(true);
    }
    else if(localStorage.getItem("accessToken")){
       fetchItems(languageCode);
    }
    else{
      console.log('No Data found Error')
    }
  }, [fetchItems, languageCode]);

  const updateLanguageCode = useCallback(async (lang) => {
    updateLanguage(lang);
    await fetchItems(lang);
  }, [fetchItems, updateLanguage]);

  const renderSpotlightModule = () => {
    return [{ data: [{ id: 0, value: spotlightContent }], key: "Spotlight Content" }].map(
      ({ data, key }, index) => {
        return (
          <Fragment key={index + key}>
            <Typography variant="h6" noWrap className={classes.subheader}>
              {key}
            </Typography>
            <Divider />
            <SpotlightModule
              submodules={data}
              setLoading={setLoading}
              refetchModules={fetchItems}
              submitData={submitSpotlightItem}
              setSpotlightContent={setSpotlightContent}
            ></SpotlightModule>
          </Fragment>
        );
      }
    );
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h6" noWrap className={classes.subheader}>
        Welcome to the Win The Right Way Portal!
      </Typography>
      {(currentUserAccess === "ContentAdmin" || currentUserAccess === "FullAdmin") &&
        <Fragment>
          <FormGroup className={classes.container} noValidate autoComplete="off">
            <TextField
              label="Language Code"
              className={classes.langaugeCode}
              defaultValue={languageCode}
              select
              size="medium"
              onChange={({ target }) => {
                setCurrentLanguageCode(target.value);
                updateLanguageCode(target.value);
              }}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              InputProps={{ className: classes.customInput }}
            >
              <MenuItem key="Chinese (Simplified)" value="zh">
                Chinese (Simplified)
              </MenuItem>
              <MenuItem key="Dutch" value="nl">
                Dutch
              </MenuItem>
              <MenuItem key="English" value="en">
                English
              </MenuItem>
              <MenuItem key="French" value="fr">
                French
              </MenuItem>
              <MenuItem key="German" value="de">
                German
              </MenuItem>
              <MenuItem key="Italian" value="it">
                Italian
              </MenuItem>
              <MenuItem key="Japanese" value="ja">
                Japanese
              </MenuItem>
              <MenuItem key="Portuguese" value="pt">
                Portuguese
              </MenuItem>
              <MenuItem key="Russian" value="ru">
                Russian
              </MenuItem>
              <MenuItem key="Spanish" value="es">
                Spanish
              </MenuItem>
            </TextField>
          </FormGroup>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h6" noWrap className={classes.subheader}>
                CEO Message
              </Typography>
              <Divider />
              <FormGroup className={classes.container} noValidate autoComplete="off">
                <TextField
                  key="activeCEOMessage"
                  id="activeCEOMessageID"
                  className={classes.textField}
                  multiline
                  value={ceoMessageContent?.getContentData?.()?.getCEOMessage?.() ?? ""}
                  onChange={event => {
                    const { value } = event.target;
                    setInputEdited(true);
                    ceoMessageContent.getContentData().setCEOMessage(value);
                    updateContent();
                  }}
                  onBlur={() => {
                    if (inputEdited) submitCEOMessage();
                    setInputEdited(false);
                  }}
                  margin="normal"
                ></TextField>
              </FormGroup>
            </Grid>
            <Grid item xs={8}>
              {renderSpotlightModule()}
            </Grid>

          </Grid>
        </Fragment>
      }
      {
        <Fragment>
          <Dialog
            open={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
          >
            <DialogTitle id="alert-dialog-title">Access Error</DialogTitle>
            <DialogContent>
              <Typography>You are not authorized to access this application</Typography>
              <DialogActions>
                <Button onClick={() => setShowErrorModal(false)}
                  color="primary">
                  ok
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Fragment>
      }
    </div>
  );
}
